.dot-progress {
    background-color: #323232;
    height: 25px;
    width: 25px;
    display: flex;
    
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.dot-progress-active {
    background-color: orange;
    height: 25px;
    width: 25px;
    display: inline-block;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.dot-root {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

@media  (max-width: 690px) {
    .dot-root {
        display: flex;
        flex-wrap: wrap;
    }
}