.orange-hover {
    border-color: white;
    color: white;
}

.orange-hover:hover {
    color: orange;
    border-color: orange;
    background-color: rgba(255, 255, 255, 0.6);
    transition: 0.1s;
}