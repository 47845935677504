.navbar-nav {
  margin-left: 15px;
}

.navbar-container {
  margin: 10px;
  
}

.navigation-button {
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  transition: 0.1s;
  text-shadow: 0 0 5px #5b5b5b;
  font-weight: 300;
}

.navigation-button:focus {
  outline-color: transparent;
  outline-style: none;
  border: 0 transparent;
  color: azure;
}


.navigation-button:hover {
  color: orange;
}