@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100&display=swap");

* {
    z-index: 1;
}

:root {
    --relSize: calc(1vw + 1vh);
}

.code-snippet {
    font-family: "JetBrains Mono", sans-serif;
    font-size: calc(var(--relSize) / 1.5);
    opacity: 0.3;
    color: white;
    will-change: contents;
}

.code-snippet-text {
    font-family: "JetBrains Mono", sans-serif;
    font-size: 1.4vh;
    opacity: 0.3;
    color: white;
    z-index: -1;
    will-change: contents;
}

.content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3vw;
}

.grayscale {
  filter: grayscale(100%);
  transition: 0.1s;
}

.grayscale:hover {
  filter: grayscale(0);
}

.nav-root {
    display: flex;
    min-height: 100vh;
}

@media only screen and (max-width: 1100px) {
    .nav-root {
      display: block;
    }
}

.typed-cursor {
    font-size: calc(var(--relSize) * 1.4);
}

