.my-name-header {
    letter-spacing: 0.05vw;
    z-index: 1;
    font-size: 3.5vh;
    font-weight: 350;
}

.icon {
    width: 25px;
    height: 25px;
}
