.header-description-text {
    color: orange;
    font-size: calc(var(--relSize) * 1.5);
    letter-spacing: calc(var(--relSize) / 10);
}

.profile-photo {
    display: inline;
    justify-self: center;
    object-fit: cover;
    width: 60%;
    min-width: 400px;
    aspect-ratio: 0.8;
}

.home-root {
    display: flex;
}

@media only screen and (max-width: 1200px) {
    .home-root {
        display: grid;
        margin-left: auto;
        margin-right: auto;
    }
   
    .profile-photo {
        border-radius: 50%;
        aspect-ratio: 1;
        width: 60%;
        min-width: 200px;
    }
}